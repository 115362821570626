import axios from 'axios'
import store from '../store/index'
// import { serveData } from './serve'
import Cookies from 'js-cookie'
 const request = axios.create({
    baseURL: 'api',
     timeout:600000
 })
// 请求拦截器
request.interceptors.request.use(function (config) {
    if (Cookies.get('token')) {
        config.headers.token = Cookies.get('token');
       }
    return config
}, function (error) {
    return Promise.reject(error)
})
export default request